/* eslint-disable react-hooks/exhaustive-deps */
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill';
import './i18n';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react';
import * as serviceWorker from './serviceWorker';
import { icons } from './assets/icons';
import LoadingSpinner from './components/LoadingSpinner';
import store from './app/store';
import { consts, urls } from './utils/config';
import { Axios, setStorageItem } from './utils/ApiClient';
import Page500 from './views/pages/Page500';
import { SecureAppUsingAuth0, SecureAppUsingAffinity } from './AppWrappers';

React.icons = icons;

const landingPageUrl = `${window.location.origin}/clientmanagement`;
const root = document.getElementById('root');

const onRedirectCallback = () => {
  // workaround to clear window.location.search - Auth0 session code and state in url
  window.location = landingPageUrl;
};

const initApp = async () => {
  try {
    const { data: baseSettings } = await Axios.request(urls.baseSettings);
    const authSettings = baseSettings.data;
    setStorageItem(consts.authSettings, authSettings);

    if (authSettings.use === consts.auth0) {
      ReactDOM.render(
        <Suspense fallback={<LoadingSpinner absolute />}>
          <Auth0Provider
            useRefreshTokens
            useRefreshTokensFallback
            cacheLocation="localstorage"
            domain={authSettings.domain}
            clientId={authSettings.reactAppClientId}
            authorizationParams={{
              redirect_uri: landingPageUrl
            }}
            onRedirectCallback={onRedirectCallback}
          >
            <Provider store={store}>
              <SecureAppUsingAuth0 organization={authSettings.organization} />
            </Provider>
          </Auth0Provider>
        </Suspense>,
        root
      );
    } else {
      ReactDOM.render(
        <Suspense fallback={<LoadingSpinner absolute />}>
          <Provider store={store}>
            <SecureAppUsingAffinity />
          </Provider>
        </Suspense>,
        root
      );
    }
  } catch (error) {
    ReactDOM.render(
      <Suspense fallback={<LoadingSpinner absolute />}>
        <Page500 error={error.toString()} onInit />
      </Suspense>,
      root
    );
  }
};

initApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
