import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CCol, CContainer, CLink, CRow } from '@coreui/react';

const Page403 = ({ message, onInit }) => {
  const { t } = useTranslation('translation');
  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer className="h-50">
        <CRow className="justify-content-center">
          <CCol md="6">
            <div className="clearfix">
              <h1 className="float-left display-3 mr-4">403</h1>
              <h4 className="pt-3">
                {t('profile.403')}
              </h4>
              <p className="text-muted float-left">{t('profile.403Message')}</p>
            </div>
            {!onInit && <CLink to="/">{t('routes.Home')}</CLink>}
            {message && (
            <>
              <hr />
              <p>{message}</p>
            </>
            )}
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

Page403.defaultProps = {
  message: undefined,
  onInit: false
};

Page403.propTypes = {
  message: PropTypes.string,
  onInit: PropTypes.bool
};

export default Page403;
