import React from 'react';
import moment from 'moment-timezone';

export const isJsonString = (str) => {
  try { JSON.parse(str); } catch (e) { return false; }
  return true;
};

export const getQueryStringValue = (query, key) => {
  const params = query.startsWith('?')
    ? query.substring(1).split('&')
    : query.split('&');
  if (!params || !params.length) return null;
  const part = params.find((p) => p.startsWith(key));
  return part ? part.replace(`${key}=`, '') : null;
};

export const generateId = (prefix) => {
  const id = Math.random().toString(16).slice(-4);
  return `${(prefix).toLowerCase().replace(/ /g, '_') || 'id'}-${id}`;
};

export const pluralize = (count, noun, suffix = 's', boldCount = false) => (
  boldCount
    ? (
      <span>
        <strong>{count}</strong>
        {` ${noun}${count !== 1 ? suffix : ''}`}
      </span>
    )
    : `${count} ${noun}${count !== 1 ? suffix : ''}`);

export const isNumericString = (str) => {
  if (typeof str !== 'string') return false;
  return !Number.isNaN(str) && !Number.isNaN(parseFloat(str));
};

export const isNumeric = (v) => !Number.isNaN(v) && !Number.isNaN(parseFloat(v));

export const roundWithPrecision = (value, precision) => Math.round(value * 10 ** precision) / 10 ** precision;

export const sortByCompare = (a, b, propName) => {
  if (a[propName] < b[propName]) return -1;
  if (a[propName] > b[propName]) return 1;
  return 0;
};

export const sortByCompareDesc = (a, b, propName) => {
  if (a[propName] < b[propName]) return 1;
  if (a[propName] > b[propName]) return -1;
  return 0;
};

export const sortByDate = (a, b, propName) => {
  if (moment(a[propName]).isBefore(b[propName])) return -1;
  if (moment(a[propName]).isAfter(b[propName])) return 1;
  return 0;
};

export const sortByDateDesc = (a, b, propName) => {
  if (moment(a[propName]).isBefore(b[propName])) return 1;
  if (moment(a[propName]).isAfter(b[propName])) return -1;
  return 0;
};

export const hasValue = (variable, numeric) => {
  if (typeof variable === 'undefined' || variable === null) return false;
  if (numeric && Number.isNaN(variable)) return false;
  if (!numeric && (variable.length === 0 || variable === ' ')) return false;
  return true;
};

export const distinctBy = (array, key) => {
  const values = [...new Map(array.map((d) => [d[key], d])).keys()];
  return values;
};

export const removeSpaces = (str) => str?.replace(/\s+/g, '');

export const onlyNumbers = (str) => str?.replace(/[^[0-9]/gi, '');

export const removeSpecialCharactersAndDoubleSpaces = (str) => str?.replace(/[^-\w\s]/gi, '').replace(/\s\s+/g, ' ');

export const removeSpecialCharactersExceptHyphensAndUnderscores = (str) => str?.replace(/[^-\w]/gi, '');

const specialTypes = ['Track Monitoring System, Tunnel profile Monitoring System'];

export const resolveRoute = (item) => (specialTypes.includes(item.typeName)
  ? removeSpaces(item.typeName)
  : item.classTypeName);

export const trimStringProps = (obj, recursively = false) => {
  const _obj = { ...obj };
  const stringFilelds = {};
  Object.keys(_obj).forEach((key) => {
    if (!_obj[key]) return;
    if (typeof _obj[key] === 'string') {
      stringFilelds[key] = _obj[key]?.trim();
    }
    if (recursively && typeof _obj[key] === 'object' && !Array.isArray(_obj[key])) {
      _obj[key] = trimStringProps(_obj[key], recursively);
    }
    if (recursively && Array.isArray(_obj[key]) && _obj[key].length > 0) {
      _obj[key] = [..._obj[key].map((item) => trimStringProps(item, recursively))];
    }
  });

  return { ..._obj, ...stringFilelds };
};

export const sameDay = (d1, d2) => moment(d1).startOf('day').isSame(moment(d2).startOf('day'));

export const timestampsBetweenDates = (startDate, endDate, amount = 1, unit = 'hour', format = true) => {
  const dates = [];

  if (moment(startDate).isSame(moment(endDate))) {
    return dates;
  }

  const currDate = moment(startDate);
  const lastDate = moment(endDate);

  if (format === true) dates.push(currDate.format());
  else if (typeof format === 'string') dates.push(currDate.format(format));
  else dates.push(currDate.clone().toDate());

  while (currDate.add(amount, unit).diff(lastDate) < 0) {
    const next = currDate.startOf('hour');
    if (format === true) dates.push(next.format());
    else if (typeof format === 'string') dates.push(next.format(format));
    else dates.push(next.clone().toDate());
  }

  return dates;
};
